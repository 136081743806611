<template>
    <div :class="[
            'form-group',
            computedClasses,
            { disabled: disabled },
            { invalid: hasError },
        ]">
        <template v-if="label">
            <div :class="['form-label']">
                <label :for="forProp" :class="labelClasses">
                    {{ label }}
                    <span v-if="required && editMode" class="text-red">*</span>
                </label>

                <a v-if="searchService === 'google-places' && value"
                   class="link-to-map"
                   :href="`https://maps.google.com/?q=${value}`"
                   target="_blank">
                    View on Google Map
                </a>

                <Tooltip v-if="tips" :text="tips">
                    <span class="material-symbols-outlined">info</span>
                </Tooltip>

                <div v-if="$slots.labelOptional">
                    <slot name="labelOptional"></slot>
                </div>
            </div>
        </template>

        <template v-if="editMode">
            <div class="relative">
                <span
                    v-if="icon"
                    :class="[
                        'text-input-icon material-symbols-outlined',
                        { filled: iconFilled },
                    ]">
                    {{ icon }}
                </span>

                <template v-if="datepicker">
                    <DatePicker
                        :value="value"
                        :config="datepicker"
                        :placeholder="placeholder"
                        @change="onChange"/>
                </template>
                <template v-else>
                    <template v-if="mask">
                        <Field
                            as="input"
                            :id="forProp"
                            :name="name"
                            :type="type"
                            :value="reformatValue"
                            :disabled="disabled"
                            :required="required"
                            :placeholder="placeholder"
                            :autofocus="autofocus"
                            v-mask="mask"
                            @input="onInput"
                            :class="[
                                'form-control',
                                {
                                    'with-dropdown': dropdownVariablesList.length,
                                    'with-icon': icon,
                                },
                                sizeClass,
                            ]"/>
                    </template>
                    <template v-else>
                        <Field
                            as="input"
                            :id="forProp"
                            :name="name"
                            :type="type"
                            :min="min"
                            :value="reformatValue"
                            :disabled="disabled"
                            :required="required"
                            :placeholder="placeholder"
                            :autofocus="autofocus"
                            @input="onInput"
                            :class="[
                            'form-control',
                                {
                                    'with-dropdown': dropdownVariablesList.length,
                                    'with-icon': icon,
                                },
                                sizeClass,
                            ]"/>
                    </template>
                </template>

                <DropdownAction
                    v-if="dropdownVariablesList.length"
                    buttonText="Add Token">
                    <div class="p-2">
                        <div
                            v-for="(item, key) in dropdownVariablesList"
                            :key="key"
                            class="item"
                            @click="addVariableEmit(key)">
                            {{ item }}
                        </div>
                    </div>
                </DropdownAction>
            </div>

            <template v-if="hasError">
                <div class="field-error">
                    {{ errorMessage }}
                </div>
            </template>
        </template>

        <template v-else>
            <div :class="['readonly-value', { empty: !modelValue && !value }]">
                {{ modelValue || value || "Empty" }}
            </div>
        </template>
    </div>
</template>

<script setup>
    import {computed, inject, watch} from "vue";
    import {Field} from "vee-validate";

    import Tooltip from "@/vue/components/el-ui/Tooltip.vue";
    import DropdownAction from "@/vue/components/el-ui/DropdownAction.vue";
    import DatePicker from "@/vue/components/el-ui/DatePicker.vue";

    const props = defineProps({
        classesUi: {
            type: String,
            default: "",
        },
        modelValue: [String, Number],
        value: {
            type: [String, Number],
            default: null
        },
        name: {
            type: String,
            default: "name"
        },
        type: {
            type: String,
            default: "text",
        },
        icon: String,
        iconFilled: Boolean,
        for: String,
        label: {
            type: String,
            default: ""
        },
        placeholder: String,
        mask: String,
        errors: {
            type: Object,
            default: () => ({}),
        },
        min: {
            type: Number,
            default: null
        },
        datepicker: {
            type: Object,
            default: null
        },
        readonly: Boolean,
        disabled: Boolean,
        autofocus: Boolean,
        required: Boolean,
        labelClasses: String,
        searchService: String,
        tips: String,
        width: String,
        inline: Boolean,
        size: {
            type: String,
            default: "md",
        },
        dropdownVariablesList: {
            type: Array,
            default: () => [],
        },
    });

    const reformatValue = computed(() => {
        if (typeof props.modelValue !== "string") return props.modelValue;

        return props.modelValue && props.modelValue.startsWith("$") ? props.modelValue.replace(/[^\d]/, "") : props.modelValue;
    });

    const emit = defineEmits(["update:modelValue", "add-variable"]);

    const computedClasses = computed(() => {
        let classes = "";
        if (props.width === "full") {
            classes += "col-span-full";
        }
        if (props.inline) {
            classes += " flex items-center";
        }
        classes += ` ${props.classesUi}`;
        return classes;
    });

    const sizeClass = computed(() => (props.size ? `size-${props.size}` : ""));

    const forProp = computed(() => props.for || "");

    const hasError = computed(() => props.errors && props.errors[props.name]);

    const errorMessage = computed(() =>
        props.errors ? props.errors[props.name] : "",
    );

    const injectedEditMode = inject("editMode", null);

    const editMode = computed(() => {
        if (injectedEditMode) {
            if (props.readonly) {
                return false;
            }

            return injectedEditMode.editModeIn.value;
        }

        return true;
    });

    const addVariableEmit = (key) => {
        emit("add-variable", key);
    };

    watch(editMode, (value) => {
        if (value && props.modelValue && props.modelValue === "string" && props.modelValue.startsWith("$")) {
            emit("update:modelValue", props.modelValue.replace(/[^\d]/, ""));
        }
    });

    if (editMode.value && props.value) {
        if (typeof props.value === "string" && props.value.startsWith("$")) {
            emit("update:modelValue", props.value.replace(/[^\d]/, ""));
        } else {
            emit("update:modelValue", props.value);
        }
    }

    const onInput = (event) => {
        emit("update:modelValue", event.target.value);
    };

    const onChange = (value) => {
        emit("update:modelValue", value);
    };
</script>
